/***************************************************************************************************
** SessionContext
***************************************************************************************************/

import React from 'react';

export const SessionContext = React.createContext();

export const SessionContextProvider = (props) => {
  const setCurrentPage = (pageKey) => {
    setSession((currentSession) => ({ ...currentSession, currentPageKey: pageKey }));
  };

  const initialSession = {
    currentPageKey: undefined,
    setCurrentPage: setCurrentPage,

    // KEEP FOR INTERPOLATION until we can add to interpolationData
    currentUserId: window.appContext.currentUserId,
    isCurrentUserAdmin: window.appContext.isCurrentUserAdmin,
  };

  const [session, setSession] = React.useState(initialSession);

  React.useEffect(() => {
    setSession((previousSession) => ({
      ...previousSession,
      isCurrentPage: (pageKey) => Boolean(pageKey && pageKey === previousSession.currentPageKey),
    }));
  }, [session.currentPageKey]);

  return (
    <SessionContext.Provider value={session}>
      {props.children}
    </SessionContext.Provider>
  );
};

// TODO: Rebuild session object (e.g., add missing ids/objects)

export const useSessionContext = () => {
  const context = React.useContext(SessionContext);

  if (!context) {
    throw new Error('SessionContext must be used within a SessionContextProvider');
  }

  return context;
};
